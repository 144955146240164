import { useSyncedRef } from "@react-hookz/web";
import classnames from "classnames";
import type { FunctionComponent } from "react";
import { useCallback } from "react";

import {
  getClassNameFromSize,
  getSizeFromRenderedInfo,
} from "scmp-app/components/advertisement/ad-slots/helpers";
import {
  useAdProfile,
  useAdUnitPath,
  useUserRoleTarget,
} from "scmp-app/components/advertisement/ad-slots/hooks";
import type {
  AdUnit,
  OutstreamAdUnit,
  Targeting,
} from "scmp-app/components/advertisement/ad-slots/types";
import * as gpt from "scmp-app/components/advertisement/google-publisher-tag/hooks";
import * as magnite from "scmp-app/components/advertisement/magnite-demand-manager/hooks";
import { useTrackViewability } from "scmp-app/components/advertisement/viewability-tracking";
import { useIntersection } from "scmp-app/lib/hooks/intersection";

import { useAutoRefresh } from "./auto-refresh";
import { useRenderSlotsWithBids } from "./hooks";

export type SlotProps = {
  adUnit: Exclude<AdUnit, OutstreamAdUnit>;
  autoRefreshOptions?: {
    intervalRefreshOptions?: {
      isDisable?: boolean;
    };
    scrollBackRefreshOptions?: {
      isDisable?: boolean;
      slotArticleEntityId?: string;
    };
  };
  className?: string;
  customizedAdUnitTargeting?: string;
  manualRefreshCount?: number;
  mutationObserveElement?: (element: HTMLElement | null) => void;
  sizes: googletag.GeneralSize;
  targeting?: Targeting;
  zone?: null | string;
};

export const Slot: FunctionComponent<SlotProps> = ({
  adUnit,
  autoRefreshOptions,
  className,
  customizedAdUnitTargeting,
  manualRefreshCount = 0,
  mutationObserveElement,
  sizes,
  targeting = {},
  zone,
}) => {
  const adUnitPath = useAdUnitPath(adUnit, zone);
  const userRoleTarget = useUserRoleTarget();
  const { adProfile } = useAdProfile();

  const slot = gpt.useDefineSlot(adUnitPath, sizes, {
    ...targeting,
    ...userRoleTarget,
    adProfile,
    ...magnite.useTargeting(adUnit, customizedAdUnitTargeting),
  });

  const { captureIntersectionElement, element, intersection } = useIntersection({
    threshold: 0.45,
  });
  const elementReference = useSyncedRef(element);

  const { totalRefreshCount } = useAutoRefresh({
    adUnit,
    manualRefreshCount,
    options: {
      intervalRefreshOptions: {
        isDisable: autoRefreshOptions?.intervalRefreshOptions?.isDisable,
        slotIntersectionEntry: intersection,
        slotReference: elementReference,
      },
      scrollBackRefreshOptions: {
        isDisable: autoRefreshOptions?.scrollBackRefreshOptions?.isDisable,
        slotArticleEntityId: autoRefreshOptions?.scrollBackRefreshOptions?.slotArticleEntityId,
        slotIntersectionEntry: intersection,
      },
    },
    slot,
  });
  useTrackViewability(slot, intersection);

  useRenderSlotsWithBids(slot, totalRefreshCount);

  const handleCallbackReference = useCallback(
    (element: HTMLDivElement) => {
      captureIntersectionElement(element);
      mutationObserveElement?.(element);
    },
    [captureIntersectionElement, mutationObserveElement],
  );

  const slotInfo = gpt.useRenderSlotInfo(slot);

  return (
    <div
      className={classnames(className, getClassNameFromSize(getSizeFromRenderedInfo(slotInfo)))}
      data-size={JSON.stringify(sizes)}
      id={slot?.getSlotElementId()}
      ref={handleCallbackReference}
    />
  );
};

Slot.displayName = "Slot";
