import { useDeepCompareEffect } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";

import { config } from "shared/data";

import type { Props } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import * as aps from "scmp-app/components/advertisement/amazon-publisher-service/hooks";
import { magnitePrebidJSAtom } from "scmp-app/components/advertisement/magnite-demand-manager/atoms";
import * as md from "scmp-app/components/advertisement/magnite-demand-manager/hooks";

export const useAdSlotWithRefresh = (props: Exclude<Props, "manualRefreshCount">) => {
  const [manualRefreshCount, setManualRefreshCount] = useState(0);

  useDeepCompareEffect(() => {
    setManualRefreshCount(0);
  }, [props]);

  return {
    props: {
      ...props,
      manualRefreshCount,
    },
    refresh: useCallback(() => setManualRefreshCount(v => v + 1), [setManualRefreshCount]),
  };
};

export const useRenderSlotsWithBids = (slot: googletag.Slot | null, refreshCount?: number) => {
  const { handleFetchBids: handleFetchApsBids } = aps.useFetchBids(slot);
  const { handleFetchBids: handleFetchMdBids } = md.useFetchBids(slot);
  const { isReady: isMagnitePrebidJSReady } = useAtomValue(magnitePrebidJSAtom);

  useEffect(() => {
    if (!isMagnitePrebidJSReady || !slot) return;

    const getFailsafeTimeoutPromise = () =>
      new Promise<void>(resolve => {
        setTimeout(resolve, config.advertisement.failsafeTimeout);
      });

    let hasCancel = false;

    const run = async () => {
      await Promise.race([
        Promise.all([handleFetchApsBids(), handleFetchMdBids()]),
        getFailsafeTimeoutPromise(),
      ]);
      if (!hasCancel) {
        googletag.cmd.push(() => {
          googletag.pubads().refresh([slot]);
        });
      }
    };
    void run();

    return () => {
      hasCancel = true;
    };
  }, [slot, refreshCount, handleFetchApsBids, handleFetchMdBids, isMagnitePrebidJSReady]);
};
